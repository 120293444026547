import styled from '@emotion/styled'
import React from 'react'
import ColumnBtnList from '../atoms/columnBtnList'
import ColumnH1 from '../atoms/columnH1'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  .txt-wrap {
    span {
      display: none;
    }
  }
  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;s
      height: 0;
      top: 5px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

/**
 * おすすめリストセット
 * TODO 川上申し送り事項：ここでは、おすすめとして指定された記事3件が表示される予定です
 * @constructor
 */
const ColumnRecommendLists = () => {
  const routeParam = useRouteParam('/column/details/C0003')
  const routeParam2 = useRouteParam('/column/details/C0006')
  const routeParam3 = useRouteParam('/column/details/C0011')

  return (
    <Wrapper>
      <ColumnH1 label="おすすめ" />
      <section>
        <ColumnBtnList
          href={routeParam}
          imgId="c0003.jpg"
          label="勤怠管理システムを導入したい！メリットや気をつけることとは"
          text="ワーク・ライフ・バランスや働き方改革の流れを受け、雇用形態が多様化し、社員それぞれの状況に適した形で働くことのできる環境づくりが求められるようになりました。さらに、新型コロナウイルスの流行を受け、自粛要請に伴い在宅勤務を強いられることになった企業も多いことでしょう。"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam2}
          imgId="c0006.jpg"
          label="通常の労働時間制（固定労働時間制度）と変形労働時間制について"
          text="通常の労働時間制、フレックスタイム制、裁量労働制など、我が国には働き方の多様化に対応した変則的な労働時間制が複数設けられています。どの制度を活用することでメリットの最大化を図ることができるかは、前提としておさえておくべき論点が明確化されていることで正しい選択が可能となります"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam3}
          imgId="c0011.jpg"
          label="就業時間とは？労働時間や勤務時間との違いや正しい計算方法を解説"
          text="近年、働き方の見直しを行う動きが活性化しています。そのうえで就業時間や労働時間など、ルールをしっかりと理解することが必須となります。"
          date="2022.04.15"
        />
      </section>
    </Wrapper>
  )
}

export default ColumnRecommendLists
