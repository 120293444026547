import { graphql } from 'gatsby'
import React from 'react'
import ColumnTopImage from '../../components/atoms/columnTopImage'
import SEO from '../../components/atoms/seo'
import ColumnNewCards from '../../components/molecules/columnNewCards'
import ColumnRecommendLists from '../../components/molecules/columnRecommendLists'
import ColumnLayout from '../../components/organisms/columnLayout'
import ColumnTopBody from '../../components/organisms/columnTopBody'

/**
 * コラムTOP画面
 * @constructor
 */
const ColumnIndexPage = () => (
  <ColumnLayout>
    <SEO
        title="キンクラコラム"
        description="勤怠管理や労働基準法の基礎知識から最近の法改正の解説まで。社会保険労務士の先生が執筆する、人事・総務担当者の「知りたい」が分かるお役立ちコラムを提供します。"
        ogUrl=" https://www.kintaicloud.jp/column/"
        ogType="website"
        ogTitle="キンクラコラム"
        ogDescription="勤怠管理や労働基準法の基礎知識から最近の法改正の解説まで。社会保険労務士の先生が執筆する、人事・総務担当者の「知りたい」が分かるお役立ちコラムを提供します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
    />
    {/** TOPメインビジュアル */}
    <ColumnTopImage />
    <ColumnTopBody>
      {/** 最新コラム */}
      <ColumnNewCards />
      {/** おすすめ記事一覧 */}
      <ColumnRecommendLists />
    </ColumnTopBody>
  </ColumnLayout>
)

export default ColumnIndexPage

export const pageQuery = graphql`
  query ColumnIndexPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
